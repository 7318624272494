<template>
  <section>
    <va-breadcrumbs>
      <va-breadcrumbs-item
        label="Educação"
        to="/admin/educacao/professores-all"
      />
      <va-breadcrumbs-item>
        <span style="font-style: italic; color: #0785d3">Professores</span>
      </va-breadcrumbs-item>
    </va-breadcrumbs>
    <h2 class="titulo-links-escolha">Professores</h2>
    <p>Listagem de todos os professores cadastrados no ano de {{ano}}</p>

    <div class="row"  v-if="situacaoAno">
      <div class="col-md-10"></div> 
      <div class="col-md-2">
        <va-button
          class="mr-2 mb-2"
          size="small"
          color="success"
          @click="registar()"
          style="float: right"
        >
          {{ $t("Registrar") }}</va-button
        >
      </div>
    </div>
    <va-input
      v-if="professorALL.length > 5"
      class="flex mb-2 md6"
      placeholder="Filtrar..."
      v-model="filter"
    />
    <va-data-table
      striped="true"
      hoverable="true"
      animated="true"
      :items="professorALL"
      :columns="columns"
      :per-page="perPage"
      :filter="filter"
      :current-page="currentPage"
    >
      <template #cell(info)="{ source: info }">
        <va-button
          class="mr-2 mb-2"
          size="small"
          color="#ff5722"
          @click="verTurnoProfessor(info)"
          v-if="info.funcao_id == 5  && situacaoAno"
          icon="access_alarms"
          title="Gerenciar Horários"
        >
          {{ $t("Horários") }}
        </va-button>

        <va-button
          class="mr-2 mb-2"
          size="small"
          color="#18acd8"
          @click="visualizarDisciplina(info)"
          v-if="info.funcao_id == 5  && situacaoAno"
          icon="library_books"
          title="Gerenciar Disciplinas"
        >
          {{ $t("Disciplinas") }}
        </va-button>

        <va-button
          v-if="situacaoAno"
          class="mr-2 mb-2"
          size="small"
          color="success"
          @click="professorSingle(info.id)"
        >
          {{ $t("Visualizar Tudo") }}
        </va-button>

        <va-button
          v-if="situacaoAno"
          class="mr-2 mb-2"
          size="small"
          color="danger"
          @click="deletar(info.id_)"
        >
          {{ $t("Deletar") }}
        </va-button>

      </template>

  
      <template #bodyAppend>
        <tr>
          <td colspan="8" class="table-example--pagination">
            <va-pagination v-model="currentPage" input :pages="pages" />
          </td>
        </tr>
      </template>
    </va-data-table>

    <!--modal escolas e turnos disponiveis do professor-->
    <va-modal v-model="showMediumModal" hide-default-actions>
      <slot>
        <va-button
          size="small"
          color="success"
          style="float: right"
          @click="gerenciarHorario(this.info.id,1)/*addTurnoProfessor(this.info)*/"
        >
          Adicionar
        </va-button>
        <p>
          <b>{{ tituloModal }}</b>
        </p>

        <table class="table table-hover">
          <thead>
            <tr>
              <th>Escola</th>
              <th>Turno</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="n in textoModal" :key="n">
              <td>{{ n.escola.nome }}</td>
              <td>{{ n.turno }}</td>
              <td>
                <va-button size="small" @click="gerenciarHorario(n,2)">
                  Editar
                </va-button>
                <va-button
                  size="small"
                  color="danger"
                  @click="deletarHorario(n.id)"
                >
                  Deletar
                </va-button>
              </td>
            </tr>
          </tbody>
        </table>
        <va-button size="small" color="secondary" style="float: right" @click="fecharModal">
          Voltar
        </va-button>
      </slot>
    </va-modal>

    <!--modal especialização do professor-->
    <va-modal v-model="showDisciplinaModal" hide-default-actions>
      <slot>
        <va-button size="small" color="success" style="float: right" @click="gereciadorEspecializacao(this.info.id,1)">
          Adicionar
        </va-button>
        <p>
          <b>{{ tituloModal }}</b>
        </p>

        <table class="table table-hover">
          <thead>
            <tr>
              <th>Etapas</th>
              <th>Disciplina</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="n in textoModal" :key="n">
              <td>{{ n.segmento.nome }}</td>
              <td>{{ n.disciplina.nome }}</td>
              <td>
                <va-button size="small" @click="gereciadorEspecializacao(n,2)/*,editarDisciplina(3,'teste')*/">
                  Editar
                </va-button>
                <va-button
                  size="small"
                  color="danger"
                  @click="deletarDisciplina(n.id)"
                >
                  Deletar
                </va-button>
              </td>
            </tr>
          </tbody>
        </table>

        <va-button size="small" color="secondary" style="float: right" @click="fecharModal">
          Voltar
        </va-button>
      </slot>
    </va-modal>

    <!--modal Edição horario -->
    <va-modal v-model="showgerenciarHorarioModal" hide-default-actions>
      <slot>
        <form>
          <div class="row">
            <!--Adicionar-->
            <div v-if="tipo == 1">
              <p><b>Adicionar Turno do Professor</b></p>
              <div class="form-group col-md-12" >
                <label >Selecione a Escola:</label>
                <select class="form-control" v-model="escolaSelecionada">
                  <option v-for="n in escolaALL" :key="n" :value="n.id" >{{n.nome}}</option>
                </select>
              </div>
              <div class="form-group col-md-12" >
                <label >Selecione a Escola:</label>
                <select class="form-control" v-model="turnoSelecionado">
                  <option v-for="n in horariosAll" :key="n" :value="n.nome" >{{n.nome}}</option>
                </select>
              </div>

              <div class="flex" style="margin-top:15px">
                <va-button class="mr-2 mb-2" @click="cadastrarHorario()">
                  {{ $t("Adicionar") }}
                </va-button>
              </div>
            </div>
            
            <!--Editar-->
            <div v-if="tipo == 2">
              <p><b>Editar Turno do Professor</b></p>
              <div class="form-group col-md-12" >
                <label >Selecione a Escola:</label>
                <select class="form-control" v-model="escolaSelecionada">
                  <option v-for="n in escolaALL" :key="n" :value="n.id" >{{n.nome}}</option>
                </select>
              </div>
              <div class="form-group col-md-12" >
                <label >Selecione a Escola:</label>
                <select class="form-control" v-model="turnoSelecionado">
                  <option v-for="n in horariosAll" :key="n" :value="n.nome" >{{n.nome}}</option>
                </select>
              </div>

              <div class="flex" style="margin-top:15px">
                <va-button class="mr-2 mb-2" @click="editarHorario">
                  {{ $t("Editar") }}
                </va-button>
              </div>
            </div>
          </div>
        </form>    
      </slot>
    </va-modal>    

    <!--modal Edição especialização do professor -->
    <va-modal v-model="showgerenciarDisciplinaModal" hide-default-actions :before-open="ajustar()">
      <slot>
        <form>
          <div class="row">
            <!--adicionar-->
            <div v-if="this.tipo == 1">
              <p><b>Adicionar Disciplina que o Professor dará Aula {{faseSelecionada}}</b></p>
              <div class="form-group col-md-12">
                <label>Selecione o segmento:</label>
                <select class="form-control" v-model="segmentoSelecionado" @change="disciplinaAtualizar" >
                  <option v-for="n in segmentoALL" :key="n" :value="n.id"  >{{n.nome}}</option>
                </select>
                <br>
              </div>
              <div class="row" v-if="segmentoSelecionado == 4"> 
                <slot>
                  <p>Selecione para quais fazes:</p>
                  <div class="flex md12">
                    <fieldset>
                      <va-radio
                        option="option1"
                        v-model="faseSelecionada"
                        label="II FASE - V FASE"
                      />
                      <va-radio
                        option="option2"
                        v-model="faseSelecionada"
                        label="VI FASE - IX FASE"
                      />
                    </fieldset>
                  </div>
                </slot>
              </div>
              <div class="row" v-if="segmentoSelecionado == 1 || segmentoSelecionado == 3"> 
                <slot>
                  <p>Selecione a especialização do professor:</p>
                  <div class="flex md6">
                    <fieldset>
                      <va-radio
                        option="option1"
                        v-model="radioSelectedOption"
                        label="(1º ao 5º ano)"
                      />
                      <va-radio
                        v-if="segmentoSelecionado == 1"
                        option="option2"
                        v-model="radioSelectedOption"
                        label="Educação infantil"
                      />
                    </fieldset>
                  </div>
                </slot>
              </div>

              <div class="form-group col-md-12" v-if="segmentoSelecionado == 4 && faseSelecionada == 'option1'">
                <br>
                <h6><strong>O professor será responsável por todas as disciplina(Similar ao Fundamental I)</strong></h6>
              </div>
             
              <div class="form-group col-md-12" v-if="(segmentoSelecionado == 2 && disciplinaALL.length) || (faseSelecionada == 'option2' && segmentoSelecionado == 4)">
                <label >Selecione a nova disciplina:</label>
                <select class="form-control" v-model="disciplinaSelecionada">
                  <option v-for="n in disciplinaALL" :key="n" :value="n.id" >{{n.nome}}</option>
                </select>
              </div>
              <div class="flex" style="margin-top:15px">
                <va-button class="mr-2 mb-2" @click="cadastrarDisciplina()">
                  {{ $t("Adicionar") }}
                </va-button>
              </div>
            </div>

            <!--edicao-->
            <div v-if="this.tipo == 2">
              <p><b>Edição de Disciplina que o Professor da Aula</b></p>
              <div class="form-group col-md-12">
                <label>Selecione o novo segmento:</label>
                <select class="form-control" v-model="segmentoSelecionado" @change="disciplinaAtualizar" >
                  <option v-for="n in segmentoALL" :key="n" :value="n.id"  >{{n.nome}}</option>
                </select>
              </div>

              <div class="row" v-if="segmentoSelecionado == 1 || segmentoSelecionado == 3"> 
                <slot>
                  <p>Selecione a especialização do professor:</p>
                  <div class="flex md6">
                    <fieldset>
                      <va-radio
                        option="option1"
                        v-model="radioSelectedOption"
                        label="(1º ao 5º ano)"
                      />
                      <va-radio
                        v-if="segmentoSelecionado == 1"
                        option="option2"
                        v-model="radioSelectedOption"
                        label="Creche"
                      />
                    </fieldset>
                  </div>
                </slot>
              </div>

              <div class="form-group col-md-12" v-if="disciplinaALL.length">
                <label >Selecione a nova disciplina:</label>
                <select class="form-control" v-model="disciplinaSelecionada">
                  <option v-for="n in disciplinaALL" :key="n" :value="n.id" >{{n.nome}}</option>
                </select>
              </div>
              
              <div class="flex" style="margin-top:15px">
                <va-button class="mr-2 mb-2"  @click="editarDisciplina">
                  {{ $t("Alterar") }}
                </va-button>
              </div>
            </div>
          </div>
        </form>
      </slot>
    </va-modal>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Professor } from "@/class/professor.js";
import { Calendario } from "@/class/calendario.js";
import { Servidores } from "@/class/servidores.js";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Configuracao } from "@/class/configuracao.js";
import { Escola } from "@/class/escolas.js";

export default defineComponent({
  data() {
    const columns = [
      { key: "nome", label: "Nome", sortable: true },
      { key: "info", label: "Configuração" },
    ];

    return {
      faseSelecionada:'',
      radioSelectedOption:"option1",
      ehFundamentalI:0,
      situacaoAno:1,
      filter: '',
      showMediumModal: false,
      showDisciplinaModal: false,
      showgerenciarHorarioModal:false,
      showgerenciarDisciplinaModal: false,
      tipo:1,
      ano:0,

      tituloModal: "",
      textoModal: "",
      info: [],
      disciplinaALL:[],
      segmentoALL:[],
      escolaALL:[],
      horariosAll:[],

      id_item:0,
      segmentoSelecionado:'',
      disciplinaSelecionada:'',
      professorSelecionado:'',
      escolaSelecionada:1,
      turnoSelecionado:'',
      professor:[],

      professorALL: [],
      columns,
      perPage: 15,
      currentPage: 1,
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.professorALL.length / this.perPage)
        : this.professorALL.length;
    },
  },
  methods: {
    registar() {
      this.$router.push({ name: "professorCadastro" });
    },
    async deletar(id) {
      if (window.confirm("Você realmente deseja retirar este professor da lista de professores?")) {   
        try{
          let data = await Professor.remover(id);
          this.$vaToast.init({
            message: "Deletado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'bottom-left',
            duration: 2500,
            fullWidth: false,
            color:'success'
          })
          this.listarTudo();
        }catch(e){
          let a = e.response.data.erro;
          this.$vaToast.init({
            message: a,
            iconClass: 'fa-star-o',
            position: 'bottom-left',
            duration: 2500,
            fullWidth: false,
            color:'#e42222'
          })
        }
      }
    },
    professorSingle(id) {
      this.$router.push({ name: "professor", params: { id: id } });
    },
    async listarTudo() {
      try {
        this.professorALL = [];
        let anoSelect = sessionStorage.getItem("anoSelecionado");
        let calenda = await Calendario.calendarioAtual();
        let calend_ano = calenda.data.ano;
        if(calenda != anoSelect){
          this.ano = anoSelect;
        }else{
          this.ano = calend_ano;
        }

        let data = await Professor.obtemTodosAnoLetivo(this.ano);
        for (const el of data.data) {
          if (!el.situacao) continue;
          let novo = {
            id: el.servidor_id,
            nome: el.professor.nome,
            info: {
              id: el.servidor_id,
              id_:el.id,
              nome: el.professor.nome,
              funcao_id: el.login.funcao,
            },
          };
          this.professorALL.push(novo);
        }

      } catch (e) {}
    },

    ajustar() {
      this.faseSelecionada = "";
      this.segmentoSelecionado = "";
      this.disciplinaSelecionada = "";
    },

    async verTurnoProfessor(info) {
      this.tituloModal = "HORÁRIOS DE TRABALHO DO PROFESSOR";
      this.turnosDeTrabalho = [];
      let calendario = await Calendario.calendarioAtual();
      let data = await Professor.buscarTurnosProfessores(
        calendario.data.ano,
        info.id
      );

      this.tituloModal = "";
      this.textoModal = "";
      this.textoModal = data.data;
      this.info = [];
      this.info = info;

      this.showMediumModal = true;
    },
    async visualizarDisciplina(info) {
      this.tituloModal = "DISCIPLINAS MINISTRADAS PELO PROFESSOR";
      let data = await Servidores.visualizarEspecializaoProf(info.id);

      this.tituloModal = "";
      this.textoModal = "";
      this.textoModal = data.data;
      this.info = [];
      this.info = info;

      this.showDisciplinaModal = true;
    },
    addTurnoProfessor(info) {
      this.$router.push({ name: "addTurnoProfessor", params: { id: info.id } });
    },
    editarSegmento(id) {
      this.$router.push({ name: "servidorEditar", params: { id: id } });
    },
    addDisciplina(info) {
      this.$router.push({
        name: "addDisciplinaProfessor",
        params: { id: info.id },
      });
    },
    editarDisciplina(id,nome) {
      this.$router.push({
        name: "editarDisciplinaProfessor",
        params: { id: id, nomeProfessor: nome },
      });
    },
    deletarDisciplina(info) {
      this.$router.push({
        name: "deletarDisciplinaProfessor",
        params: { id: info.id },
      });
    },
    async gerenciarHorario(item,tipo){
      let dt = await Escola.obtemTodos(); 
      this.escolaALL = dt.data;
      let dt2 = await Configuracao.obtemTodos();
      this.horariosAll = dt2.data[0].turnos;

      this.showMediumModal = false;
      this.showDisciplinaModal = false;
      if(tipo == 1){
        this.tipo = 1;
        this.professorSelecionado = item;
      }
      if(tipo == 2){
        this.tipo = 2;
        this.id_item = item.id;
        this.professorSelecionado = item.servidor_id;
        this.escolaSelecionada = item.escola_id;
        this.turnoSelecionado = item.turno;
      }
      this.showgerenciarHorarioModal = true;
    },

    async gereciadorEspecializacao(item,tipo) {
      this.showMediumModal = false;
      this.showDisciplinaModal = false;
      let dt = await SegmentoEscolar.obtemTodos(); 
      this.segmentoALL = dt.data;
      if(tipo == 1){
        this.tipo = 1;
        this.professorSelecionado = item;
      }

      if(tipo == 2){
        if (item.segmento_id == 1) {
          if (item.disciplina_id == 1) {  
            this.radioSelectedOption = "option1";
          }
          else {
            this.radioSelectedOption = "option2";
          }
        }
        this.tipo = 2;
        this.id_item = item.id;
        this.segmentoSelecionado = item.segmento_id;
        this.disciplinaSelecionada = item.disciplina_id;
        this.professorSelecionado = item.servidor_id;
        
        await this.disciplinaAtualizar();
      } 

      this.showgerenciarDisciplinaModal = true;
    },
    async disciplinaAtualizar(){
      this.disciplinaALL =[];
      if(this.segmentoSelecionado != 1 && this.segmentoSelecionado != 3){
        let dt = await DisciplinaEscolar.obtemTodos();
        for (const el of dt.data) {
          if(el.id != 1 && el.id != 2){
            let novo = {
              id: el.id,
              nome: el.nome,
              status: el.status,
            }
            this.disciplinaALL.push(novo);
          }
        }
      }else{
        this.disciplinaSelecionada = 1;
       
      }
    },

    async cadastrarHorario(){
      try {
        let novo = {
          ano: this.ano,
          servidor_id: this.professorSelecionado,
          escola_id: this.escolaSelecionada,
          turno: this.turnoSelecionado,
        };

        this.professor = [];
        this.professor.push(novo);

        let verif = {
          servidor_id: this.professorSelecionado,
          escola_id: this.escolaSelecionada,
          ano: this.ano,
        }; 

        const verturno = await Professor.verTurnosdoProfessor(verif);
        if(verturno.data.length > 0){
          for (const el of verturno.data) {
            if(el.turno == this.turnoSelecionado){
              alert('O professor ja possui Turno nessa escola!');
              return false;
            }
          }
        }

        const data = await Professor.addTurnoProfessor(this.professor[0]);

        this.showgerenciarHorarioModal = false;
        this.listarTudo();

        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      } catch (e) {
        
      }
    },

    async editarHorario(){
      try {
        let novo = {
          id: this.id_item,
          ano: this.ano,
          servidor_id: this.professorSelecionado,
          escola_id: this.escolaSelecionada,
          turno: this.turnoSelecionado,
        };

        this.professor = [];
        this.professor.push(novo);

        let verif = {
          servidor_id: this.professorSelecionado,
          escola_id: this.escolaSelecionada,
          ano: this.ano,
        }; 

        const verturno = await Professor.verTurnosdoProfessor(verif);
        if(verturno.data.length > 0){
          for (const el of verturno.data) {
            if(el.turno == this.turnoSelecionado){
              alert('O professor ja possui Turno nessa escola!');
              return false;
            }
          }
        }
        
        const data = await Professor.editarTurnosProfessor(novo);
        this.showgerenciarHorarioModal = false;
        await this.listarTudo();
      } catch (e) {
        
      }
    },

    async deletarHorario(id){
      try{
        const data = await Professor.removerTurnoProfessor(id);
        this.showMediumModal = false;
        this.showDisciplinaModal = false;
        this.listarTudo();
      } catch (e) {
        
      }
    },

    async cadastrarDisciplina(){
      try {
        if (this.segmentoSelecionado == 1) {
          if (this.radioSelectedOption == "option1") {
            this.disciplinaSelecionada = 1;
          }
          else {
            this.disciplinaSelecionada = 2;
          }
        }
        if (this.segmentoSelecionado == 4 && this.faseSelecionada == 'option1') {
          this.disciplinaSelecionada = 1;
        }
        let novo = {
          ano: this.ano,
          servidor_id: this.professorSelecionado,
          segmento_id: this.segmentoSelecionado,
          disciplina_id: this.disciplinaSelecionada,
        };
        this.professor = [];
        this.professor.push(novo);

        let verif = {
          servidor_id: this.professorSelecionado,
          segmento_id: this.segmentoSelecionado,
        }; 

        const verdiscplina = await Professor.buscarTodosProfessoresDeUmaDisciplina(verif);
        if(verdiscplina.data.length > 0){
          for (const el of verdiscplina.data) {
            if(el.disciplina_id == this.disciplinaSelecionada){
              alert('O professor ja possui essa Disciplina nessa Etapa!');
              return false;
            }
          }
        }

        const data = await Servidores.addEspecProfessor(this.professor[0]);
        this.showgerenciarDisciplinaModal = false;
        this.radioSelectedOption = "option1";
        this.listarTudo();

        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        });
      } catch (e) {
        
      }
    },

    async editarDisciplina(){
      try {
        if (this.segmentoSelecionado == 1) {
          if (this.radioSelectedOption == "option1") {
            this.disciplinaSelecionada = 1;
          }
          else {
            this.disciplinaSelecionada = 2;
          }
        }
        let novo = {
          id: this.id_item,
          servidor_id: this.professorSelecionado,
          segmento_id: this.segmentoSelecionado,
          disciplina_id: this.disciplinaSelecionada,
        };

        this.professor = [];
        this.professor.push(novo);

        let verif= {
          servidor_id: this.professorSelecionado,
          segmento_id: this.segmentoSelecionado,
        };

        const verdiscplina = await Professor.buscarTodosProfessoresDeUmaDisciplina(verif);
        
        if(verdiscplina.data.length > 0){
          for (const el of verdiscplina.data) {
            if(el.disciplina_id == this.disciplinaSelecionada){
              alert('O professor ja possui essa Disciplina nessa Etapa!');
              return false;
            }
          }
        }

        const data = await Servidores.alterarEspecializacao(novo);
        this.showgerenciarDisciplinaModal = false;
        await this.listarTudo();
        this.radioSelectedOption = "option1";
      } catch (e) {
        
      }
    },

    async deletarDisciplina(id){
      try{
        const data = await Servidores.deletarEspecializacao(id);
        this.showMediumModal = false;
        this.showDisciplinaModal = false;
        this.listarTudo();
      } catch (e) {
        
      }
    },
    fecharModal() {
      this.showMediumModal = 0;
      this.showDisciplinaModal = 0;
    }

  },
  beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    this.listarTudo();
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
